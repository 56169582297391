// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$SBS-Online-Training-primary: mat.define-palette(mat.$gray-palette, 900, 300, 700);
$SBS-Online-Training-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$SBS-Online-Training-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$SBS-Online-Training-theme: mat.define-light-theme((
  color: (
    primary: $SBS-Online-Training-primary,
    accent: $SBS-Online-Training-accent,
    warn: $SBS-Online-Training-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($SBS-Online-Training-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  ul {
    @apply list-disc;
    @apply pl-4;
    @apply mt-4;
    @apply mb-2;
  }
  h1 {
    @apply text-4xl;
    @apply font-bold;
    @apply mb-4;
  }
  h2 {
    @apply text-3xl;
    @apply font-bold;
    @apply mb-4;
  }
}

/* You can add global styles to this file, and also import other style files */

$admin-color: #000;
$trainer-color: #e20816;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h1, h2, h3, h4, h5, h6 {
  // font-family: "Gloock", serif;
}

.mat-mdc-button {
  // font-family: "Gloock", serif;
}

.training-table-container {
  .mat-sort-header-arrow {
    color: #fff !important;
  }
}

.admin {
  background-color: $admin-color;
}

.admin-text {
  color: $admin-color;
}

.trainer {
  background-color: $trainer-color;
}

.trainer-text {
  color: $trainer-color;
}

.ql-indent-1 {
  margin-left: 2em;
}

.ql-indent-2 {
  margin-left: 4em;
}
